import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { CaretDown } from "@phosphor-icons/react"
import PayableImporter from "../../../components/PayableReceivableImporter"
import { useState } from "react"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"

const SettlementActions = () => {
  const [isImporterOpen, setImporterOpen] = useState(false)
  const isShowImporter = useAuthorization(Permission.canUploadPayableReceivable)

  return (
    <>
      <Popover
        minimal
        arrow={false}
        className="p-0"
        trigger="mouseenter"
        interactive
        animation="fade"
        theme="light-border"
        placement="bottom"
        content={
          <Menu className="max-h-52">
            {isShowImporter && (
              <MenuItem onClick={() => setImporterOpen(true)}>
                Receivable Importer
              </MenuItem>
            )}
          </Menu>
        }
      >
        <Button
          className="!bg-gray-300"
          intent="none"
          leftIcon={<CaretDown weight="fill" />}
        >
          Action
        </Button>
      </Popover>

      {/* Render the Importer Modal */}
      {isImporterOpen && (
        <PayableImporter
          isOpen={isImporterOpen}
          onClose={() => setImporterOpen(false)}
          loaderType="invoice_settlement"
        />
      )}
    </>
  )
}

export default SettlementActions
