import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  Checks,
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Prohibit,
  Receipt,
  Timer,
  X,
} from "@phosphor-icons/react"
import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Toast,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr, minifyUuid } from "@vesatogo/utils"
import dayjs from "dayjs"
import { merge, pick } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { UseQueryArgs } from "urql"
import { useAuthorization } from "~/components/AuthorizationProvider"
import InvoiceActions from "~/components/InvoiceActions"
import PreviewInvoice from "~/components/PreviewInvoice"
import { Permission } from "~/constants/Permissions"
import { siteConfig } from "~/constants/config"
import { OrdersCustom } from "~/constants/icons"
import { APIRoutes, AppRoutes } from "~/constants/routes"
import { TwilioMessages } from "~/constants/static.items"
import {
  AllInvoicesQueryVariables,
  Invoice_Bool_Exp,
  Order_By,
  UsersQuery,
  useAllInvoiceStatusesSubscription,
  useAllInvoicesQuery,
  useAuthenticatedUserQuery,
  useBulkUpdateInvoiceMutation,
} from "~/generated/graphql"
import InvoiceFilter from "~/pages/d/trade-requests/InvoiceFilter"
import UpdateTradeStatus from "~/pages/d/trade-requests/UpdateTradeStatus"
import { config } from "~/pages/pavti/config"
import { useInvoiceSelectorList } from "~/store/invoice-selector.store"
import { downloadPdf } from "~/utils/downloadPdf"
export function downloadInvoice(id: string | number) {
  return () => {
    const token = localStorage.getItem("token")
    downloadPdf(APIRoutes.downloadInvoice(id), token).catch(err => {
      return toast.custom(
        <Toast title={"Unable to download the invoice"} intent="danger" />
      )
    })
  }
}

const COMMON_FIELDS = [
  "id",
  "current_status_id",
  "status_processing",
  "unit",
  "buyer",
  "total_amount",
  "total_quantity",
]
const COLUMNS = [
  {
    accessor: "id",
    Header({ data }) {
      const selectable = data?.filter(d => d.id)
      const { selected, selectAll } = useInvoiceSelectorList()
      const isSelectAll =
        Object.keys(selected)?.length &&
        Object.keys(selected)?.length === selectable.length
      return (
        <Checkbox
          labelClassName="!mb-4"
          checked={isSelectAll ? true : false}
          onChange={() => {
            selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
          }}
        />
      )
    },
    Cell({ value, row }) {
      const { selected, setSelected } = useInvoiceSelectorList()
      if (!row?.original?.id) return null
      return (
        <Checkbox
          checked={selected[value] ? true : false}
          onChange={() => {
            setSelected(pick(row.original, COMMON_FIELDS))
          }}
        />
      )
    },
  },
  {
    Header: <div className="pl-3">Invoice No</div>,
    accessor: "name",
    Cell({ row: { original } }) {
      const [params, setParams] = useSearchParams()
      if (!original.id) return "--"
      return (
        <span
          onClick={() => {
            params.set("invoice", original.id)
            setParams(params)
          }}
          className="link-text cursor-pointer"
        >
          Invoice #{original.id}
        </span>
      )
    },
  },
  {
    Header: "Tracking code",
    accessor: "tracking_code",
    Cell({ value }) {
      if (!value) return "--"
      return minifyUuid(value)
    },
  },
  {
    Header: "Created On",
    accessor: "created_at",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={dayjs(value).format("D MMM 'YY")}
          subtitle={dayjs(value).format("hh:mm a")}
        />
      )
    },
  },
  {
    Header: "Farmers",
    accessor: "trades",
    Cell({ value }) {
      if (!value?.length) return "--"
      return (
        <AvatarGroup>
          {value.map(value => {
            const user = value?.seller?.user?.[0]
            if (!user) return null
            const blockStyle = user.is_blocked
              ? {
                  border: "2px solid #F13434",
                  backgroundColor: "#ffe6e6",
                  color: "#F13434",
                }
              : undefined

            return (
              <Tooltip
                content={
                  <div>
                    <div>{user.full_name}</div>
                    <div>{user.external_reference_id}</div>
                  </div>
                }
                key={value.id}
              >
                <Avatar
                  style={blockStyle}
                  name={user.full_name}
                  src={user?.photo?.url}
                />
              </Tooltip>
            )
          })}
        </AvatarGroup>
      )
    },
  },
  {
    Header: "Trader",
    accessor: "buyer.user[0]",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={
            <div className="flex items-center gap-1">
              {value.full_name}{" "}
              {value.is_blocked && (
                <Prohibit color="red" size={20} weight="fill" />
              )}
            </div>
          }
          subtitle={<CodenameTag referenceId={value.external_reference_id} />}
        />
      )
    },
  },
  {
    Header: "Total Quantity",
    accessor: "total_quantity",
    Cell({ value, row: { original } }) {
      if (!value && value !== 0) return "--"
      return (
        <span>
          {value || "0"}{" "}
          <span className="text-gray-500">
            {original?.unit?.[0]?.trade_items?.[0]?.quantity_unit?.name || "na"}
          </span>
        </span>
      )
    },
  },
  {
    Header: "Total Amount",
    accessor: "total_amount",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={inr(value)} />
    },
  },
  {
    Header: "Msg Status",
    accessor: "twilio_message.status",
    Cell({ value }) {
      const twilioStatus = TwilioMessages[value]
      if (twilioStatus)
        return (
          <div className="flex gap-1">
            {twilioStatus.id === "failed" ? (
              <X size={20} weight="bold" color={twilioStatus.color} />
            ) : (
              <Checks size={20} weight="bold" color={twilioStatus.color} />
            )}
            {twilioStatus.name}
          </div>
        )
      if (value) return value
      return "--"
    },
  },
  {
    Header: "Placed By",
    accessor: "created_by",
    Cell({ value, row }) {
      if (!value) return "--"
      return (
        <Tooltip
          content={
            <div>
              <p>{value?.full_name || "na"}</p>
              <p className="text-gray-400">
                {dayjs(row?.original?.created_at).format("DD MMM 'YY | h:mm a")}
              </p>
            </div>
          }
        >
          <Avatar
            className="relative text-center"
            name={value?.name}
            src={value?.photo?.url}
          />
        </Tooltip>
      )
    },
  },
  {
    Header: "Status",
    accessor: "current_status_id",
    Cell({ row: { original }, value }) {
      return (
        <Cell
          title={
            value ? (
              <span className="flex gap-1 items-center">
                {value}{" "}
                {original?.status_processing && (
                  <Tooltip content="Status change in progress">
                    <Timer size={16} className="text-blue-300" weight="fill" />
                  </Tooltip>
                )}
              </span>
            ) : (
              "na"
            )
          }
          subtitle={dayjs(original.updated_at).format("D MMM 'YY | hh:mm A")}
        />
      )
    },
  },
  {
    Header: "",
    accessor: "updated_at",
    Cell({ row: { original } }) {
      const [params] = useSearchParams()
      const navigate = useNavigate()
      return (
        <Popover
          minimal
          arrow={false}
          className="p-0"
          trigger="mouseenter"
          interactive
          animation="fade"
          theme="light-border"
          placement="bottom"
          content={
            <Menu className="max-h-52">
              <MenuItem onClick={downloadInvoice(original.id)}>
                Download Invoice
              </MenuItem>
              <MenuItem
                onClick={() => {
                  params.set("update", original.id)
                  navigate(
                    {
                      pathname: location.pathname,
                      search: `?${createSearchParams(params)}`,
                    },
                    {
                      state: { current_status_id: original.current_status_id },
                    }
                  )
                }}
              >
                Update Status
              </MenuItem>
            </Menu>
          }
        >
          <button className="hover:bg-gray-300 rounded">
            <DotsThreeVertical size={20} />
          </button>
        </Popover>
      )
    },
  },
]

export type FilterType = {
  sb_seller?: UsersQuery["base_user"][0]
  sb_buyer?: UsersQuery["base_user"][0]
  oth_created_at?: any
}
const TradeInvoices = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ data: aData }] = useAuthenticatedUserQuery()
  const search = params.get("search")
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const [filter, setFilter] = useState<FilterType>({})
  const [debouncedSearch] = useDebouncedValue(search?.trim(), config.debounce)
  const canViewTrades = useAuthorization(Permission.ViewTrade)
  const canViewInvoices = useAuthorization(Permission.ViewInvoice)
  const buyerCode = filter.sb_buyer?.codename
  const sellerCode = filter.sb_seller?.codename
  const currentDeptCodename = aData?.me?.agent?.current_department?.codename
  const [department] = useLocalStorage({ key: "department" })
  const { selected, selectAll } = useInvoiceSelectorList()

  const createdAt = filter?.oth_created_at
  const whereClause: Invoice_Bool_Exp = {
    ...(debouncedSearch
      ? Number(debouncedSearch)
        ? { id: { _eq: Number(debouncedSearch) } }
        : { tracking_code: { _ilike: `%${debouncedSearch}%` } }
      : {}),
    created_at: createdAt
      ? {
          _lte: dayjs(createdAt).endOf("day").toISOString(),
          _gte: dayjs(createdAt).startOf("day").toISOString(),
        }
      : undefined,
    trades: {
      department: {
        reference_id: {
          _eq: department || currentDeptCodename,
        },
      },
      buyer: {
        reference_id: {
          _eq: buyerCode,
        },
      },
      seller: {
        reference_id: {
          _eq: sellerCode,
        },
      },
    },
  }
  const invoiceVariables: Omit<
    UseQueryArgs<AllInvoicesQueryVariables>,
    "query"
  > = {
    variables: {
      limit: siteConfig.pageSize,
      offset: (page - 1) * siteConfig.pageSize,
      order_by: { created_at: Order_By.DescNullsLast },
      where: whereClause,
    },
    pause: !department && !currentDeptCodename,
    requestPolicy: "network-only",
  }
  const [{ data: iData, fetching }, refetch] =
    useAllInvoicesQuery(invoiceVariables)
  const [{ data: sData }] = useAllInvoiceStatusesSubscription(invoiceVariables)
  const data = useMemo(() => merge(iData, sData), [iData, sData])

  const totalInvoices = data?.invoice_aggregate?.aggregate?.count || 0

  useEffect(() => {
    if (!canViewInvoices && currentDeptCodename) {
      navigate(AppRoutes.tradeReceipts)
    }
  }, [canViewInvoices, currentDeptCodename])
  useEffect(() => {
    if (updatedAt) {
      refetch()
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])
  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"invoices"}
          onChange={value => {
            if (value === "trades" && canViewTrades) {
              navigate(AppRoutes.tradeRequests)
            } else if (value === "receipts") {
              navigate(AppRoutes.tradeReceipts)
            }
          }}
          data={[
            canViewTrades && {
              label: "Trades",
              value: "trades",
              icon: <List className="mr-1" />,
            },
            {
              label: "Receipts",
              value: "receipts",
              icon: <Receipt className="mr-1" />,
            },
            {
              label: "Invoices",
              value: "invoices",
              icon: <OrdersCustom className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search by tracking code",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          <InvoiceFilter filter={filter} setFilter={setFilter} />
        </div>
        <div className="flex gap-3">
          <InvoiceActions />
          {!department && !currentDeptCodename ? null : (
            <Button
              onClick={() => {
                params.set("update", "all")
                setParams(params)
              }}
              variant="outline"
            >
              Update Invoice
            </Button>
          )}
        </div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        getRowProps={({ original }) => ({
          className:
            original.buyer?.user?.[0]?.is_blocked ||
            (Array.isArray(original?.trades) &&
              original?.trades.some(trade => trade?.seller?.user?.[0]?.is_blocked))
              ? "bg-red-100 hover:bg-red-100"
              : "",
        })}
        paginationProps={{
          total: Math.ceil(totalInvoices / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.invoice || []}
        meta={<>{totalInvoices} invoices</>}
      />
      <UpdateTradeStatus
        entity="invoice"
        bulkUpdateHook={useBulkUpdateInvoiceMutation}
        storeListHook={useInvoiceSelectorList}
      />
      <PreviewInvoice />
    </>
  )
}

export default TradeInvoices
