import { Modal } from "@vesatogo/grass-core"
import axios from "axios"
import React, { useRef, useState } from "react"
import toast from "react-hot-toast"
import { usePayableSelector } from "~/pages/d/payable/PayableSelector"
import * as XLSX from "xlsx"

type LoaderType = "invoice_settlement" | "net_payable_advance"

interface PayableImporterProps {
  isOpen: boolean
  onClose: () => void
  loaderType: LoaderType
}

const loaderTypeNames: Record<LoaderType, string> = {
  invoice_settlement: "Invoice Settlement",
  net_payable_advance: "Net Payable Advance",
}

const PayableImporter: React.FC<PayableImporterProps> = ({
  isOpen,
  onClose,
  loaderType,
}) => {
  const fileUploadRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const { selected } = usePayableSelector()

  const expectedHeaders: Record<LoaderType, Record<string, string[]>> = {
    invoice_settlement: { default: ["Invoice No"] },
    net_payable_advance: {
      trade: ["Trade No"],
      receipt: ["Receipt No"],
    },
  }

  const validateFileHeaders = (file: File): Promise<boolean> => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = event => {
        const data = new Uint8Array(event.target?.result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: "array" })
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
        const sheetData = XLSX.utils.sheet_to_json(firstSheet, {
          header: 1,
        }) as string[][]

        if (sheetData.length === 0) {
          setErrorMessages(["Empty Excel file. Please upload a valid file."])
          resolve(false)
          return
        }

        const fileHeaders = sheetData[0].map(header => header.trim())
        const expected =
          expectedHeaders[loaderType][selected] ||
          expectedHeaders[loaderType].default

        if (!expected.every(h => fileHeaders.includes(h))) {
          setErrorMessages([
            `Invalid headers. Expected: ${expected.join(", ")}. Found: ${
              fileHeaders[1]
            }`,
          ])
          resolve(false)
          return
        }

        resolve(true)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  return (
    <Modal
      primaryActionButtonProps={{
        text: "Submit",
        loading,
        async onClick() {
          if (!fileUploadRef.current) return
          const file = fileUploadRef.current.files?.[0]

          if (!file) {
            toast.error("Please select a file first.")
            return
          }

          setLoading(true) // Set loading before async operations
          setErrorMessages([]) // Reset errors before making request

          try {
            const isValid = await validateFileHeaders(file)
            if (!isValid) {
              toast.error("Invalid file headers. Please upload a valid file.")
              setLoading(false) // Ensure loading is stopped if validation fails
              return
            }

            const formData = new FormData()
            formData.append("loader", loaderType)
            formData.append("file", file)

            const response = await axios.post(
              `https://${
                import.meta.env.VITE_REST_URL
              }/rest/paynote/excel_data_loader/`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )

            if (
              response.data.info.errors &&
              Object.keys(response.data.info.errors).length > 0
            ) {
              const errorsArray: string[] = []
              Object.entries(response.data.info.errors).forEach(
                ([sheetIndex, errorGroup]) => {
                  Object.entries(errorGroup as Record<string, string>).forEach(
                    ([rowNumber, errorMsg]) => {
                      console.log(rowNumber)
                      console.log(errorMsg)
                      errorsArray.push(
                        `Row ${Number(rowNumber) + 2}: ${errorMsg}`
                      )
                    }
                  )
                }
              )

              setErrorMessages(errorsArray)

              if (errorsArray.length === 0) {
                toast.success(response.data.message, { duration: 3000 })
                onClose()
              }
            } else {
              toast.success(response.data.message, { duration: 3000 })
              onClose() // Only close modal if no errors
            }
          } catch (error) {
            toast.error("Upload failed. Please try again.", { duration: 3000 })
          } finally {
            setLoading(false) // Ensure loading is stopped in all cases
            if (fileUploadRef.current) {
              fileUploadRef.current.value = ""
            }
          }
        },
      }}
      secondaryActionButtonProps={{
        text: "Close",
        onClick: onClose,
      }}
      title="Bulk Payable/Receivable Importer"
      onClose={onClose}
      isOpen={isOpen}
    >
      <h2 className="text-lg font-semibold mb-4">
        {loaderTypeNames[loaderType]}{" "}
        {loaderType === "net_payable_advance"
          ? selected.charAt(0).toUpperCase() + selected.slice(1)
          : ""}
      </h2>
      <input ref={fileUploadRef} type="file" accept=".xls,.xlsx" />

      {errorMessages.length > 0 && (
        <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          <h3 className="font-semibold">Errors:</h3>
          <ul className="list-disc ml-5">
            {errorMessages.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  )
}

export default PayableImporter
